import './App.css';
import logo from './Pictures/Logo Pandemia.png';
import planetes from './Pictures/icone planetes pandemia nft.png';
import vaisseau from './Pictures/icone vaisseau pandemia nft.png';
import bouton_discord1 from'./Pictures/bouton_discord_1.png';
import bouton_discord2 from'./Pictures/bouton_discord_2.png'
import roadmap1 from './Pictures/roadmap_v3.png';
import roadmap2 from './Pictures/roadmap_portrait_v3.png';
import nftPandemia from './Pictures/icone nft pandemia.png';
import plongeur1 from './Pictures/icone_plongeur_pandemia_nft.png';
import plongeur from './Pictures/icone plongeur 2 pandemia nft.png';
import Blacksnow1 from './Pictures/Portrait 1 Blacksnow.png';
import Blacksnow2 from'./Pictures/Portrait 2 Blacksnow.png';
import Feufeu1 from './Pictures/Portrait 1 FeuFeu.png';
import FeuFeu2 from'./Pictures/Portrait 2 FeuFeu.png';
import Malko1 from './Pictures/Portrait 1 Malko.png';
import Malko2 from'./Pictures/Portrait 2 Malko.png';
import KS1 from './Pictures/Portrait 1 Fisch.png';
import KS2 from'./Pictures/Portrait 2 Fisch.png';
import logo1 from './Pictures/icone et logo Cast Labs pandemia nft.png';
import instagram from './Pictures/bouton lien instagram 2.png';
import twitter from './Pictures/bouton lien twitter 2.png';
import discord from './Pictures/bouton lien discord 2.png';
import nftcalendar from './Pictures/nft-calendar-transparent.png';
import React, { useEffect } from 'react';

function App() {
    useEffect(() => {
        const menuBtn = document.querySelector(".menu-btn");
        const rightDiv = document.querySelector(".right-div");
        const menuContent = document.querySelector(".menu-content");
    
        const handleMenuClick = () => {
          rightDiv.classList.toggle("show-menu");
          menuContent.classList.toggle("show-menu-content");
        };
    
        menuBtn.addEventListener("click", handleMenuClick);
    
        // Nettoyer l'écouteur d'événements lors du démontage du composant
        return () => {
          menuBtn.removeEventListener("click", handleMenuClick);
        };
      }, []);

  return (
    <div>
        <header class="navbar sticky-top">
            <div class="left-div"> 
                <h1> <a href="#top">Pandemia</a> </h1>
            </div>
            <div class="right-div">
                <h3> <a href="#1">Concept</a></h3>
                <h3> <a href="#2">Discord</a></h3> 
                <h3> <a href="#3">Roadmap</a></h3>
                <h3> <a href="#4">Our Teams</a></h3>
                <h3> <a href="#5">Social Media</a></h3>
                <button class="menu-btn">&#9776;</button>
            <div class="menu-content">
                <h4> <a href="#1">Concept</a></h4>
                <h4> <a href="#2">Discord</a></h4>
                <h4> <a href="#3">Roadmap</a></h4>
                <h4> <a href="#4">Our Teams</a></h4>
                <h4> <a href="#5">Social Media</a></h4>
            </div>
            </div>          
          </header>        
        <div class="d">
            <div class="d-logo"> 
                <img src={logo} alt="Logo Pandemia"/> 
            </div>  
        </div>
        <div class="dd">
            <div class="container-dd">
                <div class="left-div-dd">
                    <h1>INTRODUCTION</h1>
                    <h4>Welcome to the crazy galaxy of Pandemia, the NFT collection where we celebrate the art of the digital counterfeit ! 
                    Pandemia happily mocks pompous and expensive NFT collections, instead offering funny digital viruses in Freemint, 
                    for an investment experience where laughter is king.
                    </h4>
                </div>
                <div class="right-div-dd">
                    <img src={planetes} alt="planetes_pandemia_nft"/>
                </div>
            </div>
        </div>
        <div class="d1">
            <div class="container-d1" id='2'>
                <a href="https://discord.gg/XDz5WCvyDs" target="_blank" rel='noreferrer'>
                    <img src={bouton_discord1} alt="bouton_discord" class="main-image"/>
                    <img src={bouton_discord2} alt="bouton_discord" class="hover-image"/>     
                </a>
            </div>
        </div>
        <div class="d2" >
            <div class="">
                
            </div>
        </div>
        <div class="d3">
            <div class="container-d3" id="1">
                <div class="left-div-d3">
                    <h1>CONCEPT</h1>
                    <h4>
                        Imagine a universe where owning a Pandemia NFT is the ultimate act of rebellion against the oppressive seriousness of elitist NFT collections. 
                        Our viruses are caricatures of digital avatars that are too expensive and too serious. Forget monkeys in tuxedos and pixelated punks.
                    </h4>
                </div>
                <div class="right-div-d3">
                    <img src={vaisseau} alt="vaisseau pandemia"/>
                </div>
            </div>
        </div>
        <div class="d4"> 
            <div class="container-d4">
                <div class="left-div-d4">
                    <h1>FREEMINT</h1>
                    <h4>
                        Why pay to join the party when you can get in for free and in style? Pandemia offers its NFTs in Freemint. Here investing is not a risk but could risk making you rich.
                    </h4>
                </div>
                <div class="right-div-d4">
                    <h1>THE INVESTMENT OF THE ABSURD</h1>
                    <h4>
                        Each Pandemia NFT is a medal of humor for those who have chosen not to follow the herd of conventional investments. 
                        Our viruses are symbols of bold choice, icons for those who prefer profitability through laughter.
                    </h4>
                </div>
            </div> 
        </div>
        <div class="d12">
            <div class="container-d12" id='3'>
                <h1 class="header-d12">Roadmap - DNA OF PANDEMIA FIRST WAVE</h1>
                <div class="contain-img">
                    <img class="img1" src={roadmap1} alt="rode map"/>
                    <img class="img2" src={roadmap2} alt="rode map"/>
                </div>      
            </div>
        </div>
        <div class="d5">
        <div class="container-d5">
            <div class="new-content">
                <div class="new-list">
                    <h2 class="phase1">PHASE 1</h2>
                    <h2>Exposure</h2>
                    <h4>
                        <ul>
                            <li>Community development</li>
                            <li>Marketing campaigns</li>
                            <li>Events Giveaway</li>
                            <li>Development of the smart contract</li>
                            <li>Whitelist</li>
                        </ul>
                    </h4>
                </div>
                <div class="new-list">
                    <h2 class="phase2">PHASE 2</h2>
                    <h2>Contagion</h2>
                    <h4>
                    <ul>
                        <li>Launch of private mint</li>
                        <li>Events Giveaway</li>
                        <li>Launch of public mint</li>
                    </ul>
                    </h4>
                </div>
                <div class="new-list">
                    <h2 class="phase3">PHASE 3</h2>
                    <h2>Incubation</h2>
                    <h4>
                    <ul>
                        <li>Events Giveaway</li>
                        <li>Airdrops of mutagens to owners</li>
                    </ul>
                    </h4>
                </div>
                <div class="new-list">
                    <h2 class="phase4">PHASE 4</h2>
                    <h2>Mutation</h2>
                    <h4>
                    <ul>
                        <li>Events Giveaway</li>
                        <li>Mint of new virus variants with mutagens</li>
                    </ul>
                    </h4>   
                </div>
            </div> 
        </div>
        </div>
        <div class="d6">
            <div class="container-d6">
                    <img src={nftPandemia} alt="nft pandemia"/>
            </div>
        </div>
        <div class="d7">
            <div class="container-d7">
                <div class="left-div-d7">
                    <img src={plongeur} alt="plongeur pandémia" class="main-image"/>
                    <img src={plongeur1} alt="plongeur pandémia" class="hover-image"/>
                </div>
                <div class="right-div-d7">
                    <h1>CONCLUSION</h1>
                    <h4>
                        Pandemia aspires to become the symbol of anti-success, the beacon of humor in an ocean of seriousness. 
                        Our mission is to remind the world that success is not always measured in crypto, but sometimes in laughter.
                    </h4>
                </div>
            </div>
        </div>
        <div class="d8">
            <div class="">       
            </div>
        </div>
        <div class="d9" id='4'>
            <h1 class="header-d9">OUR TEAM</h1>
            <div class="container-d9">
                <div class="image-item1">
                    <img src={Feufeu1} alt="Feufeu" class="main-image1"/>
                    <img src={FeuFeu2} alt="Feufeu" class="hover-image1"/>
                    <h4>Marketing</h4>
                    <h5>FeuFeu</h5>
                </div>
                <div class="image-item1">
                    <img src={Blacksnow1} alt="Blacksnow" class="main-image1"/>
                    <img src={Blacksnow2} alt="Blacksnow" class="hover-image1"/>
                    <h4>Community Manager</h4>
                    <h5>Blacksnow</h5>
                </div>
                <div class="image-item1">
                    <img src={Malko1} alt="Malko" class="main-image1"/>
                    <img src={Malko2} alt="Malko" class="hover-image1"/>
                    <h4>Software Developer</h4>
                    <h5>Malko</h5>
                </div>
                <div class="image-item1">
                    <img src={KS1} alt="Fisch" class="main-image1"/>
                    <img src={KS2} alt="Fisch" class="hover-image1"/>
                    <h4>Artist & Designer</h4>
                    <h5>K.S</h5>
                </div>
            </div>
        </div>
        <div class="d10">
            <div class="">

            </div>
        </div>
        <div class="d11" id="5">
            <div class="container-d11">
                <div class="left-div-d11">
                    <img src={logo1} alt="icone et logo Cast Labs pandemia nft"/>
                </div>
                <div class="right-div-d11">
                    <h2 class="header-d11">Follow us on social media !</h2>
                    <a href="https://instagram.com/pandemia.nft?igshid=NGVhN2U2NjQ0Yg==" target="_blank" rel='noreferrer'>
                        <img src={instagram} alt="Instagram" class="main-image2"/>
                    </a>
                    <a href="https://x.com/pandemianft?s=11" target="_blank" rel='noreferrer'>
                        <img src={twitter} alt="Twitter" class="main-image2"/>
                    </a>
                    <a href="https://discord.gg/XDz5WCvyDs" target="_blank" rel='noreferrer'>
                        <img src={discord} alt="Discord" class="main-image2"/>
                    </a>
                    <br/>
                    <h4>As seen on   
                        <a href="https://nftcalendar.io/" target="_blank" rel='noreferrer'>
                            <img src={nftcalendar} alt="NFTCalendar" class="main-image3"/>
                        </a>
                    </h4>
                </div>
            </div>
        </div>
        <footer>
            <p>&copy; 2024 Cast Labs. All rights reserved.</p>
        </footer>
    </div>       
  );
}

export default App;
